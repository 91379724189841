import LenderList from "./LenderList";
import LenderConfigEdit from "./LenderConfigEdit";
import LenderDetail from "./LenderDetail";

const advancements = {
  list: LenderList,
  edit: LenderConfigEdit,
  show: LenderDetail,
};

export default advancements;
