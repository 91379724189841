import React from "react";
import { useParams } from "react-router-dom";

import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ShowBase,
  SimpleShowLayout,
  TextField,
  UrlField,
  useRecordContext,
} from "react-admin";
import { Divider, Typography, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

import { BadgeColors } from "../../../utils/constants";
import NumberFieldWithTooltip from "../../../components/NumberFieldWithTooltip";
import AdditionalCostEntry from "../../../components/AdditionalCostEntry";
import LiquidateActionField from "../../../components/LiquidateActionField";
import PayableDocumentActions from "../../../components/PayableDocumentActions";

const AdvancementDetail = () => {
  return (
    <ShowBase>
      <Grid container spacing="16" my={1}>
        <Grid item sm={8}>
          <AdvancementDetailMain />
        </Grid>
        <Grid item sm={4}>
          <AdvancementDetailTimeline />
        </Grid>
      </Grid>
    </ShowBase>
  );
};

const AdvancementDetailMain = () => {
  const { id } = useParams();
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Grid container spacing="16">
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <SimpleShowLayout>
              <Typography variant="h5">Detalhes da Operação</Typography>

              <TextField label="ID" source="id" />
              <ChipField
                label="ID operação no Fundo"
                source="lender_operation_external_id"
              />

              <Divider textAlign="left">Valores da Transação</Divider>

              <Grid>
                <Grid item>
                  <SimpleShowLayout>
                    <NumberField
                      label="Valor Solicitado"
                      source="amount"
                      locales="pt-BR"
                      options={{ style: "currency", currency: "BRL" }}
                    />
                  </SimpleShowLayout>
                </Grid>
                <Grid item>
                  <SimpleShowLayout>
                    {record.tax_amount ? (
                      <FunctionField
                        label="Taxa cobrada"
                        render={(record) =>
                          `${Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(record.tax_amount)}`
                        }
                      />
                    ) : (
                      renderUnfinishedOperation("Taxa cobrada")
                    )}
                  </SimpleShowLayout>
                </Grid>
                <Grid item>
                  <SimpleShowLayout>
                    {record.additional_costs ? (
                      <NumberFieldWithTooltip
                        label="Custos Adicionais"
                        source="additional_costs_amount"
                        tooltipDisabled={record.additional_costs.length === 0}
                        locales="pt-BR"
                        options={{ style: "currency", currency: "BRL" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch",
                          }}
                        >
                          {record.additional_costs.map((cost, index) => (
                            <AdditionalCostEntry cost={cost} key={index} />
                          ))}
                        </div>
                      </NumberFieldWithTooltip>
                    ) : (
                      renderUnfinishedOperation("Custos Adicionais")
                    )}
                  </SimpleShowLayout>
                </Grid>
                <Grid item>
                  <SimpleShowLayout>
                    {record.final_amount ? (
                      <NumberField
                        label="Valor Líquido"
                        source="final_amount"
                        locales="pt-BR"
                        options={{ style: "currency", currency: "BRL" }}
                      />
                    ) : (
                      renderUnfinishedOperation("Valor Líquido")
                    )}
                  </SimpleShowLayout>
                </Grid>
              </Grid>

              <Divider textAlign="left">Situação</Divider>

              <FunctionField
                label="status"
                render={(record) => (
                  <ChipField
                    source="status_label"
                    size="small"
                    color={BadgeColors[record.status] || "default"}
                    sortable={false}
                  />
                )}
              />
              <UrlField
                label="Link para o termo de cessão"
                source="advancement_term_link"
              />
            </SimpleShowLayout>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <SimpleShowLayout>
              <Typography variant="h5">Pagamento ao Cedente</Typography>

              <ReferenceField
                label="Cedente"
                source="supplier_id"
                reference="accounts"
                link="show"
              >
                <TextField source="name" label="Cedente" sortable={false} />
              </ReferenceField>

              <FunctionField
                label="Banco"
                render={(record) => `${record.bank_code} - ${record.bank_name}`}
              />
            </SimpleShowLayout>

            <Grid container spacing="0">
              <Grid item xs={4}>
                <SimpleShowLayout>
                  <TextField label="Agência" source="bank_agency" />
                </SimpleShowLayout>
              </Grid>
              <Grid item xs={4}>
                <SimpleShowLayout>
                  <TextField
                    label="Número da Conta"
                    source="bank_account_number"
                  />
                </SimpleShowLayout>
              </Grid>
              <Grid item xs={4}>
                <SimpleShowLayout>
                  <TextField
                    label="Tipo da Conta Bancária"
                    source="bank_account_type"
                  />
                </SimpleShowLayout>
              </Grid>
            </Grid>

            <SimpleShowLayout>
              <TextField label="Titular da Conta" source="bank_owner_name" />
              <TextField
                label="Documento do Titular"
                source="bank_owner_document"
              />
            </SimpleShowLayout>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <SimpleShowLayout>
              <Typography variant="h5">Dados do Fundo</Typography>
              <ReferenceField
                label="ID"
                source="lender_id"
                reference="lenders"
                link="show"
              >
                <TextField source="id" label="ID" sortable={false} />
              </ReferenceField>
              <TextField label="Tipo de integração" source="lender_client" />
              <TextField label="Nome" source="lender_name" />
              <TextField label="Documento" source="lender_document" />
            </SimpleShowLayout>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" mb={2}>
              Títulos Antecipados
            </Typography>
            <AdvancedPayablesList advancement={record} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" mb={2}>
              Liquidações
            </Typography>
            <LiquidationList advancementId={id} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" mb={2}>
              Integrações
            </Typography>
            <IntegrationList advancementId={id} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" mb={2}>
              Webhooks
            </Typography>
            <WebhookList advancementId={id} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const AdvancementDetailTimeline = () => {
  return (
    <Grid container spacing="16">
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <SimpleShowLayout>
              <Typography variant="h5">Timeline</Typography>

              <Timeline>
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary">
                    <DateField
                      label=""
                      source="created_at"
                      locales="pt-BR"
                      showTime
                    />
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>Registro na plataforma</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary">
                    <ReferenceField
                      source="simulation_id"
                      reference="simulations"
                      link={false}
                    >
                      <DateField label="" source="base_date" locales="pt-BR" />
                    </ReferenceField>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>Simulação solicitada</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary">
                    <DateField
                      label=""
                      source="registered_at"
                      locales="pt-BR"
                      showTime
                    />
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>Registrado no Fundo</TimelineContent>
                </TimelineItem>
              </Timeline>
            </SimpleShowLayout>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const AdvancedPayablesList = ({ advancement }) => {
  const resource = advancement.status === "sold" ? "sold_payables" : "payables";
  return (
    <List
      disableSyncWithLocation
      filter={{ advancement_id: advancement.id }}
      resource={resource}
      perPage={25}
      actions={false}
      sx={{ overflowX: "auto" }}
    >
      <Datagrid bulkActionButtons={false} size="medium" rowClick="show">
        <ReferenceField
          label="Sacado"
          source="sponsor_id"
          reference="accounts"
          link={false}
        >
          <TextField source="name" label="Sacado" sortable={false} />
        </ReferenceField>

        <FunctionField
          label="Título"
          render={(record) => `${record.invoice_type} ${record.invoice_number}`}
        />
        <FunctionField
          label="Parcela"
          render={(record) =>
            `${record.installment}/${record.installment_total}`
          }
        />

        <DateField
          source="expiration_date"
          label="Vencimento"
          sortable={false}
          locales="pt-BR"
        />

        <NumberField
          label="Valor Bruto"
          source="amount"
          options={{ style: "currency", currency: "BRL" }}
        />
        {advancement.status === "sold" && (
          <NumberField
            label="Valor Aquisição"
            source="sold_amount"
            options={{ style: "currency", currency: "BRL" }}
          />
        )}

        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
        <LiquidateActionField
          label="Ação"
          title="Liquidar"
          checkEnabled={(record) => record.status === "sold"}
        />
        <PayableDocumentActions label="Documentos" />
      </Datagrid>
    </List>
  );
};

const IntegrationList = ({ advancementId }) => {
  return (
    <List
      disableSyncWithLocation
      filter={{ advancement_id: advancementId }}
      resource="advancement_integrations"
      perPage={25}
      actions={false}
    >
      <Datagrid bulkActionButtons={false} size="medium" rowClick="show">
        <TextField source="id" label="ID" sortable={false} />
        <UrlField label="URL" source="request_url" sortable={false} />
        <TextField source="duration" label="Duração" sortable={false} />
        <DateField
          source="created_at"
          label="Dt. Criação"
          showTime
          sortable={false}
          locales="pt-BR"
        />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

const WebhookList = ({ advancementId }) => {
  return (
    <List
      disableSyncWithLocation
      filter={{ advancement_id: advancementId }}
      resource="lender_webhooks"
      perPage={25}
      actions={false}
    >
      <Datagrid bulkActionButtons={false} size="medium" rowClick="show">
        <TextField source="id" label="ID" sortable={false} />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
        <DateField
          source="created_at"
          label="Dt. Criação"
          showTime
          sortable={false}
          locales="pt-BR"
        />
      </Datagrid>
    </List>
  );
};

const LiquidationList = ({ advancementId }) => {
  return (
    <List
      disableSyncWithLocation
      filter={{ advancement_id: advancementId }}
      resource="sold_payable_liquidations"
      perPage={25}
      actions={false}
    >
      <Datagrid bulkActionButtons={false} size="medium" rowClick="show">
        <ReferenceField
          label="Título"
          source="sold_payable_id"
          reference="sold_payables"
          link={false}
        >
          <TextField source="id" label="Título" sortable={false} />
        </ReferenceField>
        <DateField
          source="liquidated_at"
          label="Liquidada em"
          sortable={false}
          locales="pt-BR"
          emptyText="-"
        />
        <NumberField
          label="Valor Liquidação"
          source="amount"
          options={{ style: "currency", currency: "BRL" }}
        />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

const renderUnfinishedOperation = (label) => (
  <FunctionField
    label={label}
    render={() => (
      <>
        Operação não concluída (
        <ReferenceField
          source="simulation_id"
          reference="simulations"
          link="show"
        >
          <FunctionField render={() => "Veja os valores simulados"} />
        </ReferenceField>
        )
      </>
    )}
  />
);

export default AdvancementDetail;
