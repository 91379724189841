
import React from "react";
import { styled } from "@mui/material/styles";
import ImportSpreadsheetDialog, { BtnActionImportSpreadsheetDialog } from "../../../../components/ImportSpreadsheetDialog";
import { useDataProvider } from "react-admin";

export function ImportPayableDialog(props) {
  const {
    open,
    onClose,
    onUpload
  } = props;

  const dataProvider = useDataProvider();

  return (
    <ImportSpreadsheetDialog
      title="Importar Títulos"
      open={open}
      onClose={onClose}
    >
      <BtnActionImportSpreadsheetDialog
        onClick={onUpload}
        title="Planilha Títulos"
        description="Somente arquivos .xlsx de até 10MB"
      />
      <Divider>
        <DividerLine /><span>Outras ações</span><DividerLine />
      </Divider>
      <BtnActionImportSpreadsheetDialog
        onClick={dataProvider.downloadPayableSpreadsheetModel}
        title="Baixar planilha modelo"
        description="Modelo padrão utilizado para preenchimento"
      />
    </ImportSpreadsheetDialog>
  )
}


const Divider = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  fontSize: "14px",
  color: theme.palette.grey[600],

  span: {
    margin: "0 16px",
  },
}));

const DividerLine = styled("div")(({ theme }) => ({
  flex: 1,
  height: "1px",
  backgroundColor: theme.palette.grey[300],
}));