import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Card, Typography } from "@mui/material";
import {
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useAuthenticated,
  useDataProvider,
  useNotify,
} from "react-admin";
import { errorManageHandler } from "../../../utils/helpers";

const LenderBankAccountCreate = () => {
  useAuthenticated();

  const navigate = useNavigate();
  const { id } = useParams();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [loading, setLoading] = React.useState(false);

  const submitHandler = (data) => {
    setLoading(true);
    dataProvider
      .create("lender_bank_accounts", { data: { lender_id: id, ...data } })
      .then(() => {
        setLoading(false);
        navigate(`/lenders/${id}/show/bank_account`);
      })
      .catch((e) => {
        const { messages } = errorManageHandler(e);
        messages.forEach((message) => {
          notify(message, { type: "error" });
        });
      });
  };

  return (
    <Box p={3}>
      <Typography variant="h5" mb={2}>
        Nova Conta Bancária
      </Typography>
      <Card>
        <SimpleForm
          onSubmit={submitHandler}
          toolbar={
            <Toolbar>
              <SaveButton disabled={loading} />
            </Toolbar>
          }
        >
          <TextInput
            variant="outlined"
            label="Código Banco"
            source="bank_code"
            validate={[required()]}
          />
          <TextInput
            variant="outlined"
            label="Agência"
            source="bank_agency"
            validate={[required()]}
          />
          <TextInput
            variant="outlined"
            label="Número Conta"
            source="account_number"
            validate={[required()]}
          />
          <TextInput
            variant="outlined"
            label="Dígito Conta"
            source="account_digit"
            validate={[required()]}
          />
        </SimpleForm>
      </Card>
    </Box>
  );
};

export default LenderBankAccountCreate;
