// in src/LogoutButton.js
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { forwardRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import ExitIcon from "@mui/icons-material/PowerSettingsNew";

const LogoutButton = forwardRef((props, ref) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/logout");
  };
  return (
    <MenuItem onClick={handleClick} ref={ref}>
      <ExitIcon /> Sair
    </MenuItem>
  );
});

export default LogoutButton;
