import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  Edit,
  minValue,
  NumberInput,
  regex,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useEditContext,
  useNotify,
  useTranslate,
} from "react-admin";

const supplierDocumentTypesChoices = ["cnpj", "cpf", "both"];

const ConfigForm = () => {
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const notify = useNotify();
  const { record } = useEditContext();

  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (record) {
      setLoading(true);
      dataProvider
        .getLenderConfig(record.id)
        .then(({ data }) => {
          setLoading(false);
          setConfig(data);
        })
        .catch((error) => {
          setError(error);
          setLoading(true);
        });
    }
  }, [record]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitHandler = (data) => {
    console.log(data);
    setLoading(true);
    dataProvider
      .updateLenderConfig(record.id, data)
      .then(({ data }) => {
        notify("notifications.config.success", { type: "success" });
        setLoading(false);
        setConfig(data);
      })
      .catch((error) => {
        notify("notifications.error.default", { type: "error" });
        setError(error);
        setLoading(false);
      });
  };

  return loading ? (
    <Box py={5} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : config ? (
    <SimpleForm record={config} onSubmit={submitHandler}>
      <Typography variant="h5" mb={2}>
        Editar Configurações
      </Typography>
      <NumberInput
        variant="outlined"
        label="Limite Diário (R$)"
        source="day_amount_limit"
        validate={[required(), minValue(0)]}
      />
      <TextInput
        variant="outlined"
        label="Horário abertura"
        source="opening_time"
        validate={[
          required(),
          regex(
            /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
            "Formato inválido de hora"
          ),
        ]}
      />
      <TextInput
        variant="outlined"
        label="Horário fechamento"
        source="closing_time"
        validate={[
          required(),
          regex(
            /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
            "Formato inválido de hora"
          ),
        ]}
      />
      <SelectInput
        label="Documentos aceitos para cedente"
        source="supplier_document_types"
        choices={supplierDocumentTypesChoices.map((choice) => ({
          id: choice,
          name: translate(`document_types.${choice}`),
        }))}
        fullWidth
        validate={[required()]}
      />
    </SimpleForm>
  ) : error ? (
    <Box py={5} display="flex" justifyContent="center" alignItems="center">
      <Typography variant="p" textAlign="center">
        Oops... Something went wrong, try again later
      </Typography>
    </Box>
  ) : null;
};

const LenderConfigEdit = () => {
  return (
    <Edit>
      <ConfigForm />
    </Edit>
  );
};

export default LenderConfigEdit;
