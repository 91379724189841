import React from "react";
import styled from "styled-components";

import { Logout, MenuItemLink, useSidebarState } from "react-admin";
import Box from "@mui/material/Box";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import {
  AccountBalance,
  AccountBoxOutlined,
  Calculate,
  CreditScore,
  Handshake,
  IntegrationInstructions,
  List,
  LogoutOutlined,
  WebhookOutlined,
} from "@mui/icons-material";

import logo from "../../../assets/img/logo.svg";
import { Link } from "react-router-dom";

const Menu = () => {
  const [open] = useSidebarState();

  return (
    <Wrapper open={open}>
      <Box
        display="flex"
        justifyContent="center"
        pt={1}
        pb={1}
        boxShadow="inset 0px -1px 0px rgba(0, 0, 0, 0.12)"
      >
        <Link to="/">
          <img src={logo} alt="PEERBNK" />
        </Link>
      </Box>
      <MenuItemLink
        to="/partners"
        state={{ _scrollToTop: true }}
        primaryText="Parceiros"
        leftIcon={<Handshake />}
      />
      <MenuItemLink
        to="/accounts"
        state={{ _scrollToTop: true }}
        primaryText="Contas"
        leftIcon={<AccountBoxOutlined />}
      />
      <MenuItemLink
        to="/lenders"
        state={{ _scrollToTop: true }}
        primaryText="Fundos"
        leftIcon={<AccountBalance />}
      />
      <MenuItemLink
        to="/payables"
        state={{ _scrollToTop: true }}
        primaryText="Títulos"
        leftIcon={<List />}
      />
      <MenuItemLink
        to="/simulations"
        state={{ _scrollToTop: true }}
        primaryText="Simulações"
        leftIcon={<Calculate />}
      />
      <MenuItemLink
        to="/advancements"
        state={{ _scrollToTop: true }}
        primaryText="Antecipações"
        leftIcon={<CurrencyExchangeIcon />}
      />
      <MenuItemLink
        to="/sold_payables"
        state={{ _scrollToTop: true }}
        primaryText="Títulos Negociados"
        leftIcon={<PlaylistAddCheckIcon />}
      />
      <MenuItemLink
        to="/sold_payable_liquidations"
        state={{ _scrollToTop: true }}
        primaryText="Liquidações"
        leftIcon={<CreditScore />}
      />
      <MenuItemLink
        to="/advancement_integrations"
        state={{ _scrollToTop: true }}
        primaryText="Integrações"
        leftIcon={<IntegrationInstructions />}
      />
      <MenuItemLink
        to="/lender_webhooks"
        state={{ _scrollToTop: true }}
        primaryText="Webhooks"
        leftIcon={<WebhookOutlined />}
      />

      <Logout icon={<LogoutOutlined />} />
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  position: relative;
  padding: 10px 0 100px;
  border-right: 1px #e5e5e5 solid;
  width: ${(props) => (props.open ? "240px" : "55px")};
  min-height: 100%;

  .MuiMenuItem-root {
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
    padding: 12px 16px;
    border-left: none;
    transition: 0.2s all;

    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;

    .MuiListItemIcon-root {
      transition: 0.2s all;
      color: rgba(0, 0, 0, 0.54);
    }

    &.RaMenuItemLink-active,
    &:hover {
      background-color: rgba(25, 118, 210, 0.08);
      color: #0c8774;
      border-left: none;

      .MuiListItemIcon-root {
        color: #0c8774;
      }
    }
  }

  .logout {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

export default Menu;
