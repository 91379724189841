import React, { useState } from "react";

import { useDataProvider, FunctionField, useRefresh } from "react-admin";

import { Download, UploadFileRounded, Cancel } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ConfirmationDialogHeader from "./ConfirmationDialogHeader";
import FileUploadDialog from "./FileUploadDialog";

const PayableDocumentActions = () => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [openFileUploadPopup, setFileUploadPopupOpen] = React.useState(false);

  const handleDownload = async (payable) => {
    setLoadingDownload(true);

    const validFiles = payable.invoice_files.filter(
      (file) => file.status !== "canceled"
    );

    const fileExtension =
      validFiles.length === 1
        ? validFiles[0].filename.split(".").at(-1)
        : "zip";

    await dataProvider.getPayableDocuments(payable.id, fileExtension);
    setLoadingDownload(false);
  };

  const fileUploadHandler = (file, payableId) => {
    return dataProvider.uploadPayableFile(file, payableId).then(refresh);
  };

  return (
    <FunctionField
      render={(record) => (
        <Box display="flex">
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleDownload(record);
            }}
            sx={{ color: "primary.main" }}
            disabled={
              !record.invoice_files.length ||
              record.invoice_files.every((file) => file.status === "canceled")
            }
          >
            {loadingDownload ? <CircularProgress size={24} /> : <Download />}
          </IconButton>
          <IconButton
            onClick={(event) => {
              setFileUploadPopupOpen(true);
              event.stopPropagation();
            }}
            sx={{ color: "primary.main" }}
            disabled={
              record.status !== "waiting" ||
              record.invoice_files.some((file) => file.status !== "canceled")
            }
          >
            <UploadFileRounded />
          </IconButton>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              setCancelModalOpen(true);
            }}
            color="error"
            disabled={
              !record.invoice_files.length ||
              record.invoice_files.every((file) => file.status === "canceled")
            }
          >
            <Cancel />
          </IconButton>
          <ConfirmFileCancel
            isOpen={isCancelModalOpen}
            onClose={(event) => {
              event.stopPropagation();
              setCancelModalOpen(false);
            }}
            payableId={record.id}
          />
          <FileUploadDialog
            open={openFileUploadPopup}
            onClose={() => {
              setFileUploadPopupOpen(false);
            }}
            onConfirm={(file) => fileUploadHandler(file, record.id)}
            type="document"
          />
        </Box>
      )}
    />
  );
};

const ConfirmFileCancel = ({ isOpen, onClose, payableId }) => {
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleFileCancel = async () => {
    setLoading(true);
    await dataProvider.payableDocumentCancel(payableId);
    setLoading(false);
  };

  const handleDisapproveButtonClick = async (event) => {
    event.stopPropagation();
    await handleFileCancel();
    refresh();
    onClose(event);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {ConfirmationDialogHeader(false, "Reprovar Nota Fiscal")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tem certeza que deseja <strong>reprovar</strong> o arquivo enviado?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          disableElevation
          onClick={handleDisapproveButtonClick}
        >
          {loading ? (
            <CircularProgress
              size={25}
              thickness={3}
              style={{ margin: "0px 20px" }}
            />
          ) : (
            <Box>Reprovar</Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PayableDocumentActions;
