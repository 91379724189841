import React from "react";

import {
  ChipField,
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { Typography } from "@mui/material";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/ext-language_tools";

import { beautifyJsonString } from "../../../utils/helpers";
import { BadgeColors } from "../../../utils/constants";

const WebhookDetail = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h5">Webhook</Typography>

        <TextField label="ID" source="id" />
        <ReferenceField
          label="Antecipação"
          source="advancement_id"
          reference="advancements"
          link="show"
        >
          <TextField source="id" label="Antecipação" />
        </ReferenceField>
        <ReferenceField
          label="Lender"
          source="lender_id"
          reference="lenders"
          link="show"
        >
          <TextField source="name" label="Fundo" />
        </ReferenceField>
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
        <DateField
          label="Dt. Criação"
          source="created_at"
          showTime
          locales="pt-BR"
        />
        <DateField
          label="Dt. Atualização"
          source="updated_at"
          showTime
          locales="pt-BR"
        />

        <TextField source="messages" label="Message" />

        <FunctionField
          label="Body"
          render={(record) => (
            <AceEditor
              mode="json"
              theme="xcode"
              fontSize={14}
              showPrintMargin
              showGutter
              value={beautifyJsonString(record.body)}
              readOnly
              style={{ width: "100%" }}
              setOptions={{
                showLineNumbers: true,
                tabSize: 4,
              }}
            />
          )}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default WebhookDetail;
