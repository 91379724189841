import React from "react";

import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  ReferenceField,
  TextField,
} from "react-admin";

import PayableDocumentActions from "../../../components/PayableDocumentActions";
import { BadgeColors } from "../../../utils/constants";
import LiquidateActionField from "../../../components/LiquidateActionField";
import FilterSidebar from "../../../components/FilterSidebar";
import StyledList from "../../../components/StyledList";

const SoldPayableList = () => {
  return (
    <StyledList
      perPage={10}
      sort={{ field: "created_at", order: "DESC" }}
      aside={
        <FilterSidebar
          textFields={[
            { label: "Sacado", source: "sponsor_name" },
            { label: "Cedente", source: "supplier_name" },
          ]}
          statusList={[
            { label: "Antecipado", value: { status: "sold" } },
            {
              label: "Verificando Recebimento",
              value: { status: "verifying" },
            },
            {
              label: "Liquidado",
              value: { status: "liquidated" },
            },
          ]}
        />
      }
    >
      <Datagrid bulkActionButtons={false} size="medium" rowClick="show">
        <ReferenceField
          label="Sacado"
          source="sponsor_id"
          reference="accounts"
          sortable={false}
          link={false}
        >
          <TextField source="name" label="Sacado" />
        </ReferenceField>
        <ReferenceField
          label="Cedente"
          source="supplier_id"
          reference="accounts"
          sortable={false}
          link={false}
        >
          <TextField source="name" label="Cedente" />
        </ReferenceField>
        <FunctionField
          label="Título"
          render={(record) =>
            `${record.invoice_type} ${record.invoice_number} (${record.installment}/${record.installment_total})`
          }
        />
        <NumberField
          label="Valor Bruto"
          source="amount"
          locales="pt-BR"
          sortable={false}
          options={{ style: "currency", currency: "BRL" }}
        />
        <DateField
          source="expiration_date"
          label="Vencimento"
          locales="pt-BR"
        />

        <DateField
          source="created_at"
          label="Dt. Criação"
          showTime
          locales="pt-BR"
        />

        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
        <LiquidateActionField
          label="Ação"
          title="Liquidar"
          checkEnabled={(record) => record.status === "sold"}
        />
        <PayableDocumentActions label="Documentos" />
      </Datagrid>
    </StyledList>
  );
};

export default SoldPayableList;
