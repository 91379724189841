import React, { useState } from "react";
import { Button } from "react-admin";
import { Box, CircularProgress, Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FileIcon, defaultStyles } from "react-file-icon";

import FileCopyRoundedIcon from "@mui/icons-material/FileCopyRounded";

import xlsx_logo from "../assets/img/sheet_logo.png";

const XLSXFiletype =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

const FileUploadDialog = (props) => {
  const { onClose, open, type } = props;
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const acceptedFileTypes =
    type === "spreadsheet"
      ? ["file", XLSXFiletype]
      : ["text/xml", "application/pdf", "image/png", "image/jpeg", "image/jpg"];

  const onDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleSelectFile(e.dataTransfer.files[0]);
    }
  };

  const onInputChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleSelectFile(e.target.files[0]);
    }
  };

  const handleSelectFile = (file) => {
    // TODO: display the error notifications to the user
    console.log(file.type);
    if (!acceptedFileTypes.includes(file.type)) {
      console.log("Arquivo não suportado");
      return;
    }
    if (file.size > 10 * 1024 * 1024) {
      // 10MB
      console.log("Arquivo muito grande");
      return;
    }
    setSelectedFile(file);
  };

  const handleClose = (e) => {
    setSelectedFile(null);
    e.stopPropagation();
    onClose();
  };

  const handleSave = async (e) => {
    if (!selectedFile) return;

    setLoading(true);
    await props.onConfirm(selectedFile);
    setLoading(false);

    handleClose(e);
  };

  const handleClick = (e) => {
    e.target.role === "presentation" && // Outside the dialog
      handleClose(e);

    e.stopPropagation();
  };

  return (
    <StyledDialog open={open} onClose={handleClose} onClick={handleClick}>
      <Title>Adicionar Arquivo</Title>
      <UploadArea
        className={dragActive ? "drag-active" : ""}
        onDragEnter={onDragEnter}
      >
        {
          // This is needed to cover the whole area of the UploadArea
          // so that the inner elements don't capture drag events
          dragActive && (
            <UploadAreaCover
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDragOver={onDragOver}
              onDrop={onDrop}
            />
          )
        }
        <form id="form-file-upload">
          <input
            type="file"
            id="input-file-upload"
            accept={
              type === "spreadsheet"
                ? ".xls,.xlsx"
                : ".xml,.pdf,.png,.jpg,.jpeg"
            }
            multiple={true}
            onChange={onInputChange}
          />
          <label id="label-file-upload" htmlFor="input-file-upload">
            <span>
              Arraste e solte um arquivo aqui ou{" "}
              <LinkLikeSpan>pesquisar</LinkLikeSpan>
            </span>
          </label>
        </form>
      </UploadArea>
      <Title>Arquivo Carregado</Title>
      <UploadedPreview>
        {selectedFile ? (
          <>
            {type === "spreadsheet" ? (
              <img
                src={xlsx_logo}
                alt="Imagem de upload"
                style={{ maxHeight: "80px", objectFit: "contain" }}
              />
            ) : (
              <Box width={80}>
                <FilePreview
                  extension={selectedFile.name.split(".").at(-1) ?? ""}
                />
              </Box>
            )}
            <span>{selectedFile.name}</span>
          </>
        ) : (
          <>
            <StyledFileIcon />
            <span>O arquivo de upload aparecerá aqui</span>
          </>
        )}
      </UploadedPreview>
      <CardActions>
        <StyledOutlinedButton
          label="Cancelar"
          variant="outlined"
          onClick={handleClose}
          sx={{ flex: 1, fontSize: "15px", fontWeight: 400, maxWidth: "none" }}
        />
        {isLoading ? (
          <StyledContainedButton
            disabled
            variant="contained"
            sx={{ flex: 1, fontSize: "15px", fontWeight: 400 }}
          >
            <CircularProgress size={20} />
          </StyledContainedButton>
        ) : (
          <StyledContainedButton
            label="Salvar"
            variant="contained"
            onClick={handleSave}
            disabled={!selectedFile}
            sx={{ flex: 1, fontSize: "15px", fontWeight: 400 }}
          />
        )}
      </CardActions>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "18px 24px",
  },
});

const Title = styled("span")(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 500,
  color: theme.palette.grey[900],
  marginBottom: "18px",
}));

const LinkLikeSpan = styled("span")(({ theme }) => ({
  color: theme.palette.blue[400],
  textDecoration: "none",
  cursor: "pointer",
}));

const UploadArea = styled(Box)(({ theme }) => ({
  padding: "44px 24px",
  border: `2px dashed ${theme.palette.grey[300]}`,
  borderRadius: "4px",
  marginBottom: "32px",

  "&.drag-active": {
    opacity: 0.6,
  },

  form: {
    input: {
      display: "none",
    },

    span: {
      fontSize: "16px",
      fontWeight: 400,
    },
  },
}));

const UploadAreaCover = styled(Box)({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

const UploadedPreview = styled(Box)(({ theme }) => ({
  position: "relative",
  minHeight: "120px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "44px 0",

  span: {
    marginTop: "10px",
    textAlign: "center",
    maxWidth: "50%",
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.grey[500],
  },
}));

const StyledFileIcon = styled(FileCopyRoundedIcon)(({ theme }) => ({
  color: theme.palette.blue[400],
  fontSize: 70,
}));

const CardActions = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  marginTop: "18px",
  gap: "16px",
});

const StyledContainedButton = styled(Button)(({ theme }) => ({
  position: "static",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  padding: "7px 16px",
  fontFamily: "Roboto, sans-serif",
  fontWeight: 500,

  "&:hover": {
    backgroundColor: theme.palette.common.darkblue,
  },
}));

const StyledOutlinedButton = styled(Button)(({ theme }) => ({
  position: "static",
  border: `1px solid ${theme.palette.common.blue}`,
  borderRadius: "4px",
  padding: "6px 12px",
  maxWidth: "100px",
  color: theme.palette.common.blue,
  fontFamily: "Roboto, sans-serif",
  fontWeight: 500,

  "&:hover": {
    backgroundColor: theme.palette.faded.blue,
    borderColor: theme.palette.common.blue,
  },
}));

const FilePreview = ({ extension }) => {
  switch (extension) {
    case "pdf":
      return <FileIcon extension="pdf" labelUppercase {...defaultStyles.pdf} />;
    case "xml":
      return (
        <FileIcon
          extension="xml"
          labelUppercase
          labelColor="gold"
          type="code"
        />
      );
    case "png":
    case "jpg":
    case "jpeg":
      return (
        <FileIcon
          extension={extension}
          labelUppercase
          color="aliceblue"
          {...defaultStyles.png}
        />
      );
    default:
      return <FileIcon extension={extension} labelUppercase />;
  }
};

export default FileUploadDialog;
