import React from "react";
import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PersonAddAlt1Rounded from "@mui/icons-material/PersonAddAlt1Rounded";

const ImportSpreadsheetDialog = (props) => {
  const {
    onClose,
    open,
    title,
    children
  } = props;

  return (
    <StyledDialog open={open} onClose={onClose}>
      <Topbar>
        <Title>{title}</Title>
        <CloseButton onClick={onClose} />
      </Topbar>
      <ChildrenContainer>
        {children}
      </ChildrenContainer>
    </StyledDialog>
  );
};

export const BtnActionImportSpreadsheetDialog = (props) => {
  const {
    onClick,
    title,
    description,
  } = props;

  return (
    <CustomButtom onClick={onClick}>
      <StyledPersonAddAlt1Rounded />
      <ButtonTextContainer>
        <ButtonTitle>{title}</ButtonTitle>
        <ButtonText>{description}</ButtonText>
      </ButtonTextContainer>
    </CustomButtom>
  )
}

const Topbar = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "18px",
}));

const CloseButton = styled(CloseRoundedIcon)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 500,
  opacity: "0.4",
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
}));

const ChildrenContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "16px",
}));

const CustomButtom = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "480px",
  padding: "12px 20px",
  border: `2px solid ${theme.palette.grey[300]}`,
  borderRadius: "8px",
  cursor: "pointer",

  ":hover": {
    border: `2px solid ${theme.palette.grey[400]}`,
    backgroundColor: "rgba(0, 0, 0, 0.08);",
  },
}));

const ButtonTextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "8px 0",
});

const StyledPersonAddAlt1Rounded = styled(PersonAddAlt1Rounded)({
  fontSize: "64px",
  color: "#4F3CC94D",
  marginRight: "20px",
});

const ButtonTitle = styled("span")(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 600,
  letterSpacing: "0.22px",
  color: "#4F3CC9",
}));

const ButtonText = styled("span")(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  letterSpacing: "0.22px",
  opacity: "0.6",
}));

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "24px",
  },
});

const Title = styled("span")(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 500,
  color: theme.palette.grey[900],
}));


export default ImportSpreadsheetDialog;
