import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  ChipField,
  Datagrid,
  DateField,
  ExportButton,
  FunctionField,
  NumberField,
  ReferenceField,
  TextField,
  TopToolbar,
  useDataProvider,
  useListContext,
  useNotify,
  useRefresh,
} from "react-admin";
import PayableDocumentActions from "../../../components/PayableDocumentActions";
import { BadgeColors } from "../../../utils/constants";
import CreatePayablePopup from "./Components/CreatePayablePopup";
import { errorManageHandler } from "../../../utils/helpers";
import ActionField from "../../../components/ActionField";
import EditPayablePopup from "./Components/EditPayablePopup";
import FilterSidebar from "../../../components/FilterSidebar";
import StyledList from "../../../components/StyledList";
import { ImportPayableDialog } from "./Components/ImportPayableDialog";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import FileUploadDialog from "../../../components/FileUploadDialog";
import SelectSponsorPopup from "./Components/SelectSponsorPopup";

const PayableActions = ({ onCreate }) => {
  const { total, isLoading } = useListContext();
  const [openSpreadsheetImportPopup, setSpreadsheetImportPopupOpen] = useState(false);
  const [openFileUploadPopup, setFileUploadPopupOpen] = React.useState(false);
  const [openSelectSponserPopup, setSelectSponserPopup] = React.useState(false);
  const [sponser, setSponser] = React.useState("");

  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const closeImportPopup = () => {
    setSpreadsheetImportPopupOpen(false);
  }

  const closeFileUploadPopup = () => {
    setFileUploadPopupOpen(false);
  }

  const closeSelectSponserPopup = () => {
    setSelectSponserPopup(false);
  }


  const handlerUploadPayable = () => {
    closeImportPopup();
    setSelectSponserPopup(true);
  }

  const saveSponser = (sponserId) => {
    setSponser(sponserId);
    closeSelectSponserPopup();
    setFileUploadPopupOpen(true);
  }

  const sendPayableSpreadsheet = (file) => {
    dataProvider.uploadPayableSpreadsheet(file, sponser).then(() => {
      refresh();
      notify("Títulos criados com sucesso!", { type: "success" });
    }).catch((e) => {
      const { messages } = errorManageHandler(e);
      console.error(messages);
      notify(
        "Não foi possível salvar as informações da planilha. Por favor os dados e tente novamente.",
        { type: "error", multiLine: true }
      );
    });
  };

  return (
    <TopToolbar sx={{ flex: 1 }}>
      <div style={{ flex: 1 }}>
        <Button variant="outlined" label="Cadastrar Título" onClick={onCreate}>
          <AddIcon />
        </Button>
      </div>
      <ImportPayableDialog open={openSpreadsheetImportPopup} onClose={closeImportPopup} onUpload={handlerUploadPayable} />
      <SelectSponsorPopup
        isOpen={openSelectSponserPopup}
        onClose={closeSelectSponserPopup}
        onConfirm={saveSponser}
      />
      <FileUploadDialog
        open={openFileUploadPopup}
        onClose={closeFileUploadPopup}
        onConfirm={sendPayableSpreadsheet}
        type="spreadsheet"
      />
      <Button
        label="Importar"
        size="small"
        startIcon={<GroupAddRoundedIcon />}
        onClick={() => setSpreadsheetImportPopupOpen(true)}
      />
      <ExportButton disabled={isLoading || total === 0} />
    </TopToolbar>
  );
};

const PayableList = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const [editingPayable, setEditingPayable] = useState(null);

  const handleCreate = (formData) => {
    setLoading(true);
    dataProvider
      .create("payables", {
        // Convert cents to reais
        data: {
          ...formData,
          amount: formData.amount / 100.0,
          invoice_net_amount: formData.invoice_net_amount / 100.0,
        },
      })
      .then(() => {
        refresh();
        setLoading(false);
        setCreatePopupOpen(false);
        notify("Título criado com sucesso!", { type: "success" });
      })
      .catch((e) => {
        const { messages } = errorManageHandler(e);
        setErrorMessages(messages);
        setLoading(false);
        notify(
          "Não foi possível cadastrar o título. Por favor verifique os avisos no formulário.",
          { type: "error", multiLine: true }
        );
      });
  };

  const handleEdit = (formData) => {
    setLoading(true);
    dataProvider
      .update("payables", {
        // Convert cents to reais
        id: editingPayable.id,
        data: {
          ...formData,
          payable_id: editingPayable.id,
          amount: formData.amount / 100.0,
          invoice_net_amount: formData.invoice_net_amount / 100.0,
        },
      })
      .then((response) => {
        refresh();
        setLoading(false);
        setEditingPayable(null);
        notify(
          response.data.advancement_proceeded
            ? "Todos títulos foram preenchidos. Antecipação pronta para registro."
            : "Título editado com sucesso!",
          { type: "success" }
        );
      })
      .catch((e) => {
        const { messages } = errorManageHandler(e);
        setErrorMessages(messages);
        setLoading(false);
        notify(
          "Não foi possível editar o título. Por favor verifique os avisos no formulário.",
          { type: "error", multiLine: true }
        );
      });
  };

  return (
    <>
      <CreatePayablePopup
        isOpen={isCreatePopupOpen}
        loading={isLoading}
        onClose={() => {
          setCreatePopupOpen(false);
          setErrorMessages([]);
        }}
        onConfirm={(data) => handleCreate(data)}
        errorMessages={errorMessages}
      />
      <EditPayablePopup
        payable={editingPayable}
        loading={isLoading}
        onClose={() => {
          setEditingPayable(null);
          setErrorMessages([]);
        }}
        onConfirm={(data) => handleEdit(data)}
        errorMessages={errorMessages}
      />
      <StyledList
        actions={<PayableActions onCreate={() => setCreatePopupOpen(true)} />}
        perPage={10}
        sort={{ field: "expiration_date", order: "ASC" }}
        aside={
          <FilterSidebar
            textFields={[
              { label: "Sacado", source: "sponsor_name" },
              { label: "Cedente", source: "supplier_name" },
              { label: "Número da NF", source: "invoice_number" },
            ]}
            statusList={[
              { label: "Disponível", value: { status: "available" } },
              {
                label: "Aguardando Documentação",
                value: { status: "waiting" },
              },
              { label: "Em Análise", value: { status: "analyzing" } },
              {
                label: "Aguardando Transferência",
                value: { status: "processing" },
              },
              { label: "Negociado", value: { status: "sold" } },
            ]}
          />
        }
      >
        <Datagrid bulkActionButtons={false} size="medium" rowClick="show">
          <ReferenceField
            label="Sacado"
            source="sponsor_id"
            reference="accounts"
            sortable={false}
            link={false}
          >
            <TextField source="name" label="Sacado" sortable={false} />
          </ReferenceField>
          <ReferenceField
            label="Cedente"
            source="supplier_id"
            reference="accounts"
            sortable={false}
            link={false}
          >
            <TextField source="name" label="Cedente" sortable={false} />
          </ReferenceField>
          <FunctionField
            label="Título"
            render={(record) =>
              `${record.invoice_type} ${record.invoice_number} (${record.installment}/${record.installment_total})`
            }
          />
          <NumberField
            label="Valor"
            source="amount"
            locales="pt-BR"
            sortable={false}
            options={{ style: "currency", currency: "BRL" }}
          />
          <DateField
            source="expiration_date"
            label="Vencimento"
            locales="pt-BR"
          />
          <DateField
            source="advancement_requested_date"
            label="Dt. Solicitação"
            locales="pt-BR"
          />
          <FunctionField
            label="status"
            render={(record) => (
              <ChipField
                source="status_label"
                size="small"
                color={BadgeColors[record.status] || "default"}
                sortable={false}
              />
            )}
          />
          <ActionField
            label="Ação"
            title="Preencher Documentação"
            checkEnabled={(record) =>
              ["waiting", "analyzing"].includes(record.status)
            }
            onClick={(record) => {
              setEditingPayable(record);
            }}
          />
          <PayableDocumentActions label="Documentos" />
        </Datagrid>
      </StyledList>
    </>
  );
};

export default PayableList;
