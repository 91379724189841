import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";

const AuthLayout = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 100vh;

  background-color: #e8e8e8;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

AuthLayout.propTypes = {
  children: PropTypes.any,
};

export default AuthLayout;
