import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import {
  AutocompleteInput,
  Form,
  ReferenceInput,
  required,
} from "react-admin";


const SelectSponsorPopup = ({
  isOpen,
  onClose,
  onConfirm,
}) => {

  const onSubmit = (formdata) => {
    onConfirm(formdata.sponsor_id);
  }

  return (
    <Dialog open={isOpen} onClose={onClose} >
      <DialogTitle>Selecione o Sacado</DialogTitle>
      <Form onSubmit={onSubmit}>
        <DialogContent sx={{ width: "480px" }}>
          <ReferenceInput
            label="Sacado"
            source="sponsor_id"
            reference="accounts"
            filter={{ type: "sponsor" }}
          >
            <AutocompleteInput
              label="Sacado"
              variant="outlined"
              optionText="name"
              fullWidth
              validate={[required()]}
            />
          </ReferenceInput>
        </DialogContent>
        <DialogActions sx={{ margin: "20px" }}>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="contained" disableElevation type="submit">
            Salvar
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default SelectSponsorPopup;
