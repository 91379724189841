import React from "react";

import { Box, Typography } from "@mui/material";
import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  useRedirect,
} from "react-admin";
import { BadgeColors } from "../../../../utils/constants";

const UserList = ({ accountId }) => {
  const redirect = useRedirect();

  return (
    <List
      disableSyncWithLocation
      resource={`accounts/${accountId}/users`}
      empty={
        <Box textAlign="center" width="100%" mt={3}>
          <Typography variant="h4" paragraph>
            Sem dados
          </Typography>
        </Box>
      }
      perPage={25}
      actions={false}
    >
      <Datagrid
        size="medium"
        bulkActionButtons={false}
        rowClick={(userId) => redirect(`/users/${userId}/show`)}
      >
        <FunctionField
          label="Usuário"
          render={(record) => (
            <Box>
              <strong>{record.name}</strong>
              <br />
              <span>{record.email}</span>
            </Box>
          )}
        />
        <DateField
          label="Dt. Atualização"
          source="updated_at"
          showTime
          locales="pt-BR"
        />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

export default UserList;
