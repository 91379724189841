import React from "react";
import PropTypes from "prop-types";

import { Box, Typography } from "@mui/material";
import {
  ChipField,
  Datagrid,
  FunctionField,
  List,
  TextField,
} from "react-admin";

import { BadgeColors } from "../../../../utils/constants";

const BankAccountList = ({ accountId }) => {
  return (
    <List
      disableSyncWithLocation
      resource={`accounts/${accountId}/bank_accounts`}
      empty={
        <Box textAlign="center" width="100%" mt={3}>
          <Typography variant="h4" paragraph>
            Sem dados
          </Typography>
        </Box>
      }
      perPage={25}
      actions={false}
    >
      <Datagrid size="medium" bulkActionButtons={false}>
        <FunctionField
          label="Conta Bancária"
          render={(record) => (
            <Box>
              <strong>
                {record.bank_code} - {record.bank_name}
              </strong>
              <br />
              <span>{record.bank_agency}</span>
              <br />
              <span>{record.bank_account_number}</span>
            </Box>
          )}
        />
        <TextField label="Tipo Conta" source="bank_account_type" />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

BankAccountList.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default BankAccountList;
