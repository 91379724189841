export const errorManageHandler = (e) => {
  let messages = [],
    nextUrl = "";
  if (e.response) {
    if (e.response.status === 401) {
      nextUrl = "/login";
    } else if (e.response.status === 403) {
      nextUrl = "/login";
    }
    if (e.response.status === 0) {
      messages = ["Erro de rede"];
    } else {
      messages =
        e.response.data && e.response.data.messages
          ? e.response.data.messages
          : ["notifications.error.default"];
    }
  } else {
    messages = ["notifications.error.default"];
  }
  return { messages, nextUrl };
};

export const beautifyJsonString = (str) => {
  if (!str) return "";

  let newStr = "";
  let tabs = "";

  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    const nextChar = str[i + 1];
    const afterNextChar = str[i + 2];
    if (["{", "["].includes(char)) {
      tabs += "\t";
      newStr += char + "\n" + tabs;
    } else if (["}", "]"].includes(char)) {
      tabs = tabs.replace("\t", "");
      newStr += "\n" + tabs + char;
    } else if (char === "," && (nextChar !== " " || afterNextChar === '"')) {
      newStr += char + "\n" + tabs;
    } else {
      newStr += char;
    }
  }

  return newStr;
};

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

// Converts a Date object to a string in the format "yyyy-MM-dd".
// The same function used by react-admin to convert dates in DateInput:
// https://github.com/marmelab/react-admin/blob/91c3d5eb7fd2356ac3d5c137782bf9096866b3e2/packages/ra-ui-materialui/src/input/DateInput.tsx
export const convertDateToString = (date) => {
  if (!(date instanceof Date) || isNaN(date.getDate())) return "";
  const pad = "00";
  const yyyy = date.getFullYear().toString();
  const MM = (date.getMonth() + 1).toString();
  const dd = date.getDate().toString();
  return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};
