import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { email, Form, required, TextInput } from "react-admin";

const ContactPopup = ({ loading, open, setOpen, submitHandler }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Cadastrar sócio</DialogTitle>
      <Form onSubmit={submitHandler} noValidate>
        <DialogContent>
          <TextInput
            variant="outlined"
            source="c_name"
            label="Nome"
            disabled={loading}
            fullWidth
            defaultValue=""
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="email"
            label="Email"
            disabled={loading}
            fullWidth
            validate={[
              required("Este campo é obrigatório"),
              email("Invalid email"),
            ]}
          />
          <TextInput
            variant="outlined"
            source="phone_number"
            label="Telefone"
            disabled={loading}
            fullWidth
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="position"
            label="Cargo"
            disabled={loading}
            fullWidth
            validate={[required("Este campo é obrigatório")]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancelar</Button>
          <Button type="submit" disabled={loading}>
            Salvar
            {loading && (
              <CircularProgress
                color="inherit"
                size={15}
                thickness={2}
                style={{ marginLeft: "10px" }}
              />
            )}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

ContactPopup.propTypes = {
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
};

export default ContactPopup;
