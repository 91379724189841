import React from "react";
import PropTypes from "prop-types";

import { Box, Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  DateField,
  NumberField,
  ShowBase,
  SimpleShowLayout,
  TextField,
  useDataProvider,
  useNotify,
} from "react-admin";

import RegistrationDataPopup from "./RegistrationDataPopup";

import { errorManageHandler } from "../../../../utils/helpers";

const RegistrationData = ({
  accountId,
  cnpjInfo,
  open: openPopup,
  onOpen,
  onClose,
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [existingData, setExistingData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (accountId) {
      dataProvider
        .getAccountRegistrationData(accountId)
        .then((res) => {
          setExistingData(res.data);
        })
        .catch((e) => console.log(e));
    }
  }, [accountId]); // eslint-disable-line react-hooks/exhaustive-deps

  const createRegistrationDataHandler = (data) => {
    setLoading(true);
    dataProvider
      .create(`accounts/${accountId}/details`, {
        data: { ...data, account_id: accountId },
      })
      .then((res) => {
        setLoading(false);
        onClose();
        notify("Cadastrar dados", { type: "success" });
        setExistingData(res.data);
      })
      .catch((e) => {
        setLoading(false);
        const { messages } = errorManageHandler(e);
        messages.forEach((message) => {
          notify(message, { type: "error" });
        });
      });
  };

  return (
    <ShowBase>
      <RegistrationDataPopup
        cnpjInfo={cnpjInfo}
        existingData={existingData}
        loading={loading}
        open={openPopup}
        onClose={onClose}
        submitHandler={createRegistrationDataHandler}
      />
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h5" mt={3}>
          Dados Cadastrais
        </Typography>
        <Button
          variant="text"
          size="small"
          startIcon={<Add />}
          onClick={onOpen}
        >
          {!existingData ? "Cadastrar" : "Atualizar"} dados
        </Button>
      </Box>

      {existingData ? (
        <SimpleShowLayout record={existingData}>
          <TextField source="size" label="Porte da Empresa" />
          <NumberField
            label="Receita Bruta Anual"
            source="annual_gross_revenue"
            options={{ style: "currency", currency: "BRL" }}
          />
          <NumberField
            label="Capital Social"
            source="capital_stock"
            options={{ style: "currency", currency: "BRL" }}
          />
          <TextField source="constitution_form" label="Constituição" />
          <DateField
            source="constitution_date"
            label="Data Constituição"
            locales="pt-BR"
          />
          <TextField source="service_code" label="CNAE Atividade Principal" />
          <TextField
            source="situacao_receita_federal"
            label="Situaçao Receita Federal"
          />
        </SimpleShowLayout>
      ) : (
        <Typography variant="p" mt={2}>
          Sem dados
        </Typography>
      )}
    </ShowBase>
  );
};

RegistrationData.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default RegistrationData;
