import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { NumberField } from "react-admin";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { ClickAwayListener } from "@mui/material";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    [`& .${tooltipClasses.arrow}`]: {
      "&:before": {
        border: `1px solid ${theme.palette.grey[300]}`,
      },
      color: theme.palette.background.default,
      fontSize: "large",
    },
  };
});

const NumberFieldWithTooltip = ({ tooltipDisabled, ...otherProps }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <NumberField {...otherProps} />
      {!tooltipDisabled && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <HtmlTooltip
              title={otherProps.children}
              placement="right"
              onClose={handleTooltipClose}
              open={open}
              disableHoverListener
              TransitionProps={{ timeout: 200 }}
              TransitionComponent={Fade}
            >
              <HelpOutlineIcon
                color="action"
                onClick={handleTooltipOpen}
                sx={{
                  cursor: "pointer",
                  marginLeft: "7px",
                  fontSize: "1.25rem",
                }}
              />
            </HtmlTooltip>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default NumberFieldWithTooltip;
