import React from "react";
import PropTypes from "prop-types";

import { Box, Typography } from "@mui/material";
import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  List,
} from "react-admin";

const RepresentativeList = ({ accountId }) => {
  return (
    <List
      disableSyncWithLocation
      resource={`accounts/${accountId}/representatives`}
      empty={
        <Box textAlign="center" width="100%" mt={3}>
          <Typography variant="h4" paragraph>
            Sem dados
          </Typography>
        </Box>
      }
      perPage={25}
      actions={false}
    >
      <Datagrid size="medium" bulkActionButtons={false}>
        <FunctionField
          label="Representante"
          render={(record) => (
            <Box>
              <strong>{record.name}</strong>
              <br />
              <span>{record.email}</span>
            </Box>
          )}
        />
        <FunctionField
          label="Documento"
          render={(record) => (
            <Box>
              <span>{record.document_type}</span>
              <br />
              <span>{record.document}</span>
            </Box>
          )}
        />
        <BooleanField label="Assina termo de cessão?" source="sign_cession" />
        <DateField
          label="Dt. Criação"
          source="created_at"
          showTime
          locales="pt-BR"
        />
        <DateField
          label="Dt. Atualização"
          source="updated_at"
          showTime
          locales="pt-BR"
        />
      </Datagrid>
    </List>
  );
};

RepresentativeList.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default RepresentativeList;
