import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const ConfirmationDialogHeader = (success, title) => {
  let Icon;
  let backgroundColor;

  if (success) {
    Icon = CheckRoundedIcon;
    backgroundColor = "#2E7D32";
  } else {
    Icon = CloseOutlinedIcon;
    backgroundColor = "#ED6C02";
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          backgroundColor,
          borderRadius: "50%",
          width: 40,
          height: 40,
          marginRight: 15,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon style={{ color: "white" }} />
      </div>
      {title}
    </div>
  );
};

export default ConfirmationDialogHeader;
