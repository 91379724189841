import * as React from "react";
import axios from "axios";

import { AppBar, UserMenu } from "react-admin";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Widgets } from "@mui/icons-material";

import LogoutButton from "./LogoutButton";
import Notifications from "../../../components/Notifications";
import { ACCOUNT_BASE_URL } from "../../../utils/constants";

const MyUserMenu = () => (
  <>
    <Notifications />
    <UserMenu>
      <LogoutButton />
    </UserMenu>
  </>
);

const CustomAppBar = (props) => {
  const [apps, setApps] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`${ACCOUNT_BASE_URL}/backoffice/apps`)
      .then((res) => {
        setApps(res.data.apps || []);
      })
      .catch((e) => console.log(e.message));
  }, []);

  return (
    <AppBar
      {...props}
      color="secondary"
      elevation={1}
      userMenu={<MyUserMenu />}
      sx={{
        background: "",
        border: "none",
      }}
    >
      <Box component="span" sx={{ flex: 1 }} />
      <Autocomplete
        sx={{ width: 300 }}
        options={apps}
        autoHighlight
        loading={!apps}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <a href={option.url} style={{ color: "#333" }}>
            <Box component="li" {...props} sx={{ height: "50px" }}>
              <Widgets sx={{ marginRight: "10px" }} /> {option.name}
            </Box>
          </a>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Outros Produtos"
            inputProps={{
              ...params.inputProps,
            }}
            style={{ backgroundColor: "white", borderRadius: "5px" }}
          />
        )}
      />
      <Box component="span" sx={{ flex: 1 }} />
    </AppBar>
  );
};

export default CustomAppBar;
