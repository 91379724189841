import { Admin, CustomRoutes, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import portugueseMessages from "ra-language-pt-br";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainLayout from "./layouts/MainLayout/MainLayout";

import Login from "./pages/Auth/Login/Login";
import Logout from "./pages/Auth/Login/Logout";
import LoginCallback from "./pages/Auth/LoginCallback/LoginCallback";

import { accounts, users } from "./pages/Main/Accounts";
import advancements from "./pages/Main/Advancements";
import advancement_integrations from "./pages/Main/Integrations";
import lenders from "./pages/Main/Lenders";
import payables from "./pages/Main/Payables";
import simulations from "./pages/Main/Simulations";
import lender_webhooks from "./pages/Main/Webhooks";
import partners from "./pages/Main/Partners";
import sold_payables from "./pages/Main/SoldPayables";
import sold_payable_liquidations from "./pages/Main/Liquidations";
import LenderBankAccountCreate from "./pages/Main/Lenders/LenderBankAccountCreate";
import LenderTaxRateCreate from "./pages/Main/Lenders/LenderTaxRateCreate";
import LenderTaxRateEdit from "./pages/Main/Lenders/LenderTaxRateEdit";

import authProvider from "./providers/authProvider";
import { dataProvider } from "./providers/dataProvider";
import { lightTheme } from "./utils/theme";

const i18nProvider = polyglotI18nProvider(() => {
  return {
    ...portugueseMessages,
    notifications: {
      advancements: {
        approved: "Antecipação aprovada com sucesso!",
        rejected: "Antecipação reprovada com sucesso!",
        approved_many: "Antecipações aprovadas com sucesso!",
        rejected_many: "Antecipações reprovadas com sucesso!",
      },
      sold_payables: {
        liquidated: "Título liquidado com sucesso!",
      },
      config: {
        success: "Configurações salvas com sucesso!",
      },
      error: {
        form: "Não foi possível realizar a operação. Por favor verifique os avisos no formulário.",
        default: "Algo deu errado, tente novamente mais tarde.",
      },
    },
    payment_methods: {
      ted: "TED",
      boleto: "Boleto",
    },
    document_types: {
      cpf: "CPF",
      cnpj: "CNPJ",
      both: "CPF e CNPJ",
    },
    type: "Tipo",
    status: "Status",
    resources: {
      advancements: { name: "Antecipação |||| Antecipações" },
      accounts: { name: "Conta |||| Contas" },
      advancement_integrations: { name: "Integração |||| Integrações" },
      lenders: { name: "Fundo |||| Fundos" },
      payables: { name: "Título |||| Títulos" },
      simulations: { name: "Simulação |||| Simulações" },
      lender_webhooks: { name: "Webhook |||| Webhooks" },
      partners: { name: "Parceiro |||| Parceiros" },
      sold_payables: { name: "Títulos Negociado |||| Títulos Negociados" },
      sold_payable_liquidations: { name: "Liquidação |||| Liquidações" },
    },
  };
}, "pt-BR");

function AdminApp() {
  return (
    <Admin
      title="Antecipação - Backoffice"
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={Login}
      layout={MainLayout}
      theme={lightTheme}
      requireAuth
    >
      <Resource name="advancements" {...advancements} />
      <Resource name="accounts" {...accounts} />
      <Resource name="users" {...users} />
      <Resource name="advancement_integrations" {...advancement_integrations} />
      <Resource name="lenders" {...lenders} />
      <Resource name="payables" {...payables} />
      <Resource name="simulations" {...simulations} />
      <Resource name="lender_webhooks" {...lender_webhooks} />
      <Resource name="partners" {...partners} />
      <Resource name="sold_payables" {...sold_payables} />
      <Resource
        name="sold_payable_liquidations"
        {...sold_payable_liquidations}
      />
      <CustomRoutes>
        <Route
          path="/lenders/:id/create_bank_account"
          element={<LenderBankAccountCreate />}
        />
        <Route
          path="/lenders/:id/create_tax_rate"
          element={<LenderTaxRateCreate />}
        />
        <Route
          path="/lenders/:lender_id/tax/:id/edit"
          element={<LenderTaxRateEdit />}
        />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path="/auth/callback" element={<LoginCallback />} />
        <Route path="/logout" element={<Logout />} />
      </CustomRoutes>
    </Admin>
  );
}
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<AdminApp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
