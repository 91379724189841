import React from "react";
import { useParams } from "react-router-dom";

import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ShowBase,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { BadgeColors } from "../../../utils/constants";
import TranslatedField from "../../../components/TranslatedField";

const SoldPayableDetail = () => {
  const { id } = useParams();

  return (
    <ShowBase actions={null}>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Typography variant="h5">Título Negociado</Typography>

            <SimpleShowLayout>
              <Divider textAlign="left">Informações Gerais</Divider>

              <TextField source="id" label="ID" />
              <TextField source="external_id" label="ID interno do sacado" />

              <ReferenceField
                label="Antecipação"
                source="advancement_id"
                reference="advancements"
                link="show"
              >
                <TextField source="id" label="Antecipação" />
              </ReferenceField>
              <ReferenceField
                label="Sacado"
                source="sponsor_id"
                reference="accounts"
                link="show"
              >
                <TextField source="name" label="Sacado" />
              </ReferenceField>
              <ReferenceField
                label="Cedente"
                source="supplier_id"
                reference="accounts"
                link="show"
              >
                <TextField source="name" label="Cedente" />
              </ReferenceField>
              <ReferenceField
                label="Fundo"
                source="lender_id"
                reference="lenders"
                link="show"
              >
                <TextField source="name" label="Fundo" />
              </ReferenceField>

              <DateField
                source="created_at"
                label="Dt. Criação"
                showTime
                locales="pt-BR"
              />
              <DateField
                source="updated_at"
                label="Dt. Atualização"
                showTime
                locales="pt-BR"
              />
              <DateField
                source="invoice_issue_date"
                label="Dt. Emissão NF/Contrato"
                locales="pt-BR"
              />
              <DateField
                source="expiration_date"
                label="Vencimento"
                locales="pt-BR"
              />
              <DateField
                source="sold_date"
                label="Dt. Antecipação"
                locales="pt-BR"
              />
              <NumberField source="days_advanced" label="Dias Antecipados" />
              <DateField
                source="expected_liquidation_date"
                label="Previsão de Liquidação"
                locales="pt-BR"
              />
              <DateField
                source="liquidated_at"
                label="Liquidada em"
                locales="pt-BR"
                emptyText="Ainda não liquidada"
              />
              <TranslatedField
                label="Forma de pagamento"
                source="sponsor_payment_method"
                prefix="payment_methods"
              />

              <FunctionField
                label="status"
                render={(record) => (
                  <ChipField
                    source="status_label"
                    size="small"
                    color={BadgeColors[record.status] || "default"}
                    sortable={false}
                  />
                )}
              />

              <Divider textAlign="left">Detalhes Título</Divider>

              <TextField source="invoice_key" label="Chave NF" />

              <FunctionField
                label="Título"
                render={(record) =>
                  `${record.invoice_type} ${record.invoice_number}`
                }
              />
              <FunctionField
                label="Parcela"
                render={(record) =>
                  `${record.installment}/${record.installment_total}`
                }
              />

              <NumberField
                label="Valor Total NF/Contrato"
                source="invoice_net_amount"
                options={{ style: "currency", currency: "BRL" }}
              />
              <NumberField
                label="Valor Bruto"
                source="amount"
                options={{ style: "currency", currency: "BRL" }}
              />
              <NumberField
                label="Valor Aquisição"
                source="sold_amount"
                options={{ style: "currency", currency: "BRL" }}
              />

              <NumberField
                label="Valor Taxa"
                source="tax_amount"
                options={{ style: "currency", currency: "BRL" }}
              />
              <FunctionField
                label="Taxa aplicada"
                source="tax_perc"
                render={(record) =>
                  `${Intl.NumberFormat("pt-BR", {
                    maximumFractionDigits: 4,
                  }).format(record.tax_perc)}%`
                }
              />

              <TextField label="Criado por" source="created_by" />

              <Divider textAlign="left">Liquidações</Divider>
              <LiquidationList soldPayableId={id} />
            </SimpleShowLayout>
          </CardContent>
        </Card>
      </Box>
    </ShowBase>
  );
};

const LiquidationList = ({ soldPayableId }) => {
  return (
    <List
      disableSyncWithLocation
      filter={{ sold_payable_id: soldPayableId, advancement_id: "" }}
      resource="sold_payable_liquidations"
      perPage={25}
      actions={false}
    >
      <Datagrid bulkActionButtons={false} size="medium" rowClick="show">
        <ReferenceField
          label="Antecipação"
          source="advancement_id"
          reference="advancements"
          link={false}
        >
          <TextField source="id" label="Antecipação" sortable={false} />
        </ReferenceField>
        <DateField
          source="liquidated_at"
          label="Liquidada em"
          sortable={false}
          locales="pt-BR"
          emptyText="-"
        />
        <NumberField
          label="Valor Liquidação"
          source="amount"
          options={{ style: "currency", currency: "BRL" }}
        />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

export default SoldPayableDetail;
