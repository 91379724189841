import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Form, maxLength, required, TextInput } from "react-admin";

const AddressPopup = ({ cnpjInfo, loading, open, onClose, submitHandler }) => {
  const [formData, setFormData] = React.useState({});

  React.useEffect(() => {
    if (cnpjInfo) {
      setFormData({
        zipcode: cnpjInfo.cep,
        street: cnpjInfo.logradouro,
        street_number: cnpjInfo.numero,
        complement: cnpjInfo.complemento,
        district: cnpjInfo.bairro,
        city: cnpjInfo.cidade.nome,
        state: cnpjInfo.estado.sigla,
      });
    }
  }, [cnpjInfo, open]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Novo endereço</DialogTitle>
      <Form record={formData} onSubmit={submitHandler} noValidate>
        <DialogContent>
          <TextInput
            variant="outlined"
            source="zipcode"
            label="CEP"
            disabled={loading}
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="street"
            label="Endereço"
            disabled={loading}
            fullWidth
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="street_number"
            label="Número Casa"
            disabled={loading}
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="complement"
            label="Complemento"
            disabled={loading}
            defaultValue=" "
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="district"
            label="Bairro"
            disabled={loading}
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="city"
            label="Cidade"
            disabled={loading}
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="state"
            label="Estado"
            disabled={loading}
            validate={[
              required("Este campo é obrigatório"),
              maxLength(2, "Apenas a sigla do estado"),
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button type="submit" disabled={loading}>
            Salvar
            {loading && (
              <CircularProgress
                color="inherit"
                size={15}
                thickness={2}
                style={{ marginLeft: "10px" }}
              />
            )}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

AddressPopup.propTypes = {
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
};

export default AddressPopup;
