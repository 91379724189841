import React from "react";
import PropTypes from "prop-types";

import { Box, Typography } from "@mui/material";
import { Datagrid, DateField, FunctionField, List } from "react-admin";

const AddressList = ({ accountId }) => {
  return (
    <List
      disableSyncWithLocation
      resource={`accounts/${accountId}/addresses`}
      empty={
        <Box textAlign="center" width="100%" mt={3}>
          <Typography variant="h4" paragraph>
            Sem endereços
          </Typography>
        </Box>
      }
      perPage={25}
      actions={false}
    >
      <Datagrid size="medium" bulkActionButtons={false}>
        <FunctionField
          label="Endereço"
          render={(record) => (
            <div>
              <strong>
                {record.street} {record.street_number} {record.complement}
              </strong>
              <br />
              <span>
                {record.district} - {record.city} - {record.state}
              </span>
              <br />
              <span>{record.zipcode}</span>
            </div>
          )}
        />
        <DateField
          label="Dt. Criação"
          source="created_at"
          showTime
          locales="pt-BR"
        />
        <DateField
          label="Dt. Atualização"
          source="updated_at"
          showTime
          locales="pt-BR"
        />
      </Datagrid>
    </List>
  );
};

AddressList.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default AddressList;
