import React from "react";

import { Box, Card, CardContent, Typography } from "@mui/material";
import {
  ChipField,
  DateField,
  FunctionField,
  NumberField,
  ReferenceField,
  ShowBase,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import AceEditor from "react-ace";

import { beautifyJsonString } from "../../../utils/helpers";
import { BadgeColors } from "../../../utils/constants";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/ext-language_tools";

const LiquidationDetail = () => {
  return (
    <ShowBase actions={null}>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Typography variant="h5">Liquidações</Typography>

            <SimpleShowLayout>
              <TextField source="id" label="ID" />
              <ReferenceField
                label="Antecipação"
                source="advancement_id"
                reference="advancements"
                link="show"
              >
                <TextField source="id" label="Antecipação" />
              </ReferenceField>
              <ReferenceField
                label="Título"
                source="sold_payable_id"
                reference="sold_payables"
                link="show"
              >
                <TextField source="id" label="Título" sortable={false} />
              </ReferenceField>
              <NumberField
                label="Valor Liquidação"
                source="amount"
                options={{ style: "currency", currency: "BRL" }}
              />

              <FunctionField
                label="status"
                render={(record) => (
                  <ChipField
                    source="status_label"
                    size="small"
                    color={BadgeColors[record.status] || "default"}
                    sortable={false}
                  />
                )}
              />
              <DateField
                source="created_at"
                label="Dt. Criação"
                showTime
                locales="pt-BR"
              />
              <DateField
                source="liquidated_at"
                label="Liquidada em"
                sortable={false}
                locales="pt-BR"
                emptyText="Ainda não liquidada"
              />
              <DateField
                source="updated_at"
                label="Dt. Atualização"
                showTime
                locales="pt-BR"
              />

              <TextField
                source="lender_liquidation_external_id"
                label="ID Operação Fundo"
              />
              <TextField
                source="lender_client"
                label="Client Integração Fundo"
              />
              <FunctionField
                label="Fundo Campos Extras"
                render={(record) => (
                  <AceEditor
                    mode="json"
                    theme="xcode"
                    fontSize={14}
                    showPrintMargin
                    showGutter
                    value={beautifyJsonString(
                      JSON.stringify(record.lender_liquidation_extras)
                    )}
                    readOnly
                    style={{ width: "100%" }}
                    setOptions={{
                      showLineNumbers: true,
                      tabSize: 4,
                    }}
                  />
                )}
              />
            </SimpleShowLayout>
          </CardContent>
        </Card>
      </Box>
    </ShowBase>
  );
};

export default LiquidationDetail;
