import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Card, Typography } from "@mui/material";
import {
  maxValue,
  minValue,
  NumberInput,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useUpdate,
  EditBase,
  useAuthenticated,
} from "react-admin";

const LenderTaxRateEdit = () => {
  useAuthenticated();

  const navigate = useNavigate();
  const { lender_id, id } = useParams();
  const notify = useNotify();
  const [update] = useUpdate();

  const [loading, setLoading] = React.useState(false);

  const submitHandler = (data) => {
    const {
      advancement_tax_rate,
      max_advancement_tax_rate,
      min_advancement_tax_rate,
    } = data;
    setLoading(true);
    update(
      "lenders/sponsor_taxes",
      {
        id,
        data: {
          advancement_tax_rate,
          max_advancement_tax_rate,
          min_advancement_tax_rate,
        },
      },
      {
        onError: (error) => {
          navigate(`/lenders/${lender_id}/show/tax`);
          notify(error.response.data.messages.join(", "), { type: "warning" });
        },
        onSuccess: () => {
          navigate(`/lenders/${lender_id}/show/tax`);
        },
      }
    );
  };

  return (
    <Box p={3}>
      <Typography variant="h5" mb={2}>
        Edit tax rate
      </Typography>
      <Card>
        <EditBase resource="lenders/sponsor_taxes">
          <SimpleForm
            onSubmit={submitHandler}
            toolbar={
              <Toolbar>
                <SaveButton disabled={loading} />
              </Toolbar>
            }
          >
            <NumberInput
              variant="outlined"
              label="Taxa"
              source="advancement_tax_rate"
              validate={[required(), minValue(0), maxValue(100)]}
            />
            <NumberInput
              variant="outlined"
              label="Taxa mínima"
              source="min_advancement_tax_rate"
              validate={[required(), minValue(0), maxValue(100)]}
            />
            <NumberInput
              variant="outlined"
              label="Taxa máxima"
              source="max_advancement_tax_rate"
              validate={[required(), minValue(0), maxValue(100)]}
            />
          </SimpleForm>
        </EditBase>
      </Card>
    </Box>
  );
};

export default LenderTaxRateEdit;
