import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  email,
  Form,
  maxValue,
  minValue,
  NumberInput,
  required,
  SelectInput,
  TextInput,
} from "react-admin";

const ShareHolderPopup = ({
  cnpjInfo,
  loading,
  open,
  onClose,
  submitHandler,
}) => {
  const [formData, setFormData] = React.useState({});

  React.useEffect(() => {
    if (cnpjInfo) {
      setFormData({
        sh_name: cnpjInfo.nome,
        sh_document_type: cnpjInfo.tipo === "Pessoa Física" ? "cpf" : "cnpj",
        sh_document:
          cnpjInfo.tipo === "Pessoa Física" ? "" : cnpjInfo.cpf_cnpj_socio,
      });
    }
  }, [cnpjInfo, open]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Cadastrar sócio</DialogTitle>
      <Form record={formData} onSubmit={submitHandler} noValidate>
        <DialogContent>
          <TextInput
            variant="outlined"
            source="sh_name"
            label="Nome Completo"
            disabled={loading}
            fullWidth
            defaultValue=""
            validate={[required("Este campo é obrigatório")]}
          />
          <SelectInput
            variant="outlined"
            source="sh_document_type"
            label="Tipo Documento"
            disabled={loading}
            choices={[
              { id: "cpf", name: "CPF" },
              { id: "cnpj", name: "CNPJ" },
            ]}
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="sh_document"
            label="Documento"
            disabled={loading}
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="email"
            label="Email"
            disabled={loading}
            fullWidth
            validate={[
              required("Este campo é obrigatório"),
              email("Invalid email"),
            ]}
          />
          <NumberInput
            variant="outlined"
            source="share"
            label="% Participação"
            disabled={loading}
            fullWidth
            validate={[
              required("Este campo é obrigatório"),
              minValue(0),
              maxValue(100),
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button type="submit" disabled={loading}>
            Salvar
            {loading && (
              <CircularProgress
                color="inherit"
                size={15}
                thickness={2}
                style={{ marginLeft: "10px" }}
              />
            )}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

ShareHolderPopup.propTypes = {
  cnpjInfo: PropTypes.object,
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
};

export default ShareHolderPopup;
